import moment from "moment";
import { SENTIMENT_ACTION } from "./actions";
import { PERMISSIONS } from "./permissions";

const COLLECTION_NAME = "NS_USER_PERMISSION";
const PACKAGE_COLLECTION_NAME = "NS_PACKAGE";
const GOLD_PACKAGE_ID = "3";
interface IXUData {
  permissions: object;
  requested: boolean;
  loaded: boolean;
  needRefresh: boolean;
}

const initialState: IXUData = {
  permissions: {},
  requested: false,
  needRefresh: false,
  loaded: false,
};
export function refreshUser(firebase: any, userId: string) {
  return async function thunk(dispatch: any, getState: any) {
    const state = getState();
    dispatch({
      type: SENTIMENT_ACTION.NS_USER,
      payload: {
        user: state.NsUser.user,
        agreement: state.NsUser.agreement,
        cooperate: state.NsUser.cooperate,
        loaded: false,
        requested: false,
        needRefresh: false,
      },
    });
  };
}
export function loadNsUser(firebase: any, userId: string) {
  return async function thunk(dispatch: any, getState: any) {
    const state = getState();
    if (!userId) {
      dispatch({
        type: SENTIMENT_ACTION.EMPTY_ACTION,
        payload: {},
      });
      return;
    }
    if (state.NsUser.requested && !state.NsUser.needRefresh) return;
    dispatch({
      type: SENTIMENT_ACTION.NS_USER,
      payload: {
        requested: true,
        loaded: false,
        sentiment: [],
        needRefresh: false,
      },
    });
    try {
      console.log(1);
      const userDoc = await firebase
        .firestore()
        .collection(COLLECTION_NAME)
        .doc(userId)
        .onSnapshot(
          async (doc: any) => {
            const userData = doc?.data();

            dispatch({
              type: SENTIMENT_ACTION.NS_USER,
              payload: {
                user: { "meta.per.crypto": userData["meta.per.crypto"] },
                package: userData,
                agreement: userData["meta.agreement"],
                cooperate: userData["meta.cooperate"] ?? false,
                loaded: true,
                requested: true,
                needRefresh: false,
              },
            });

            console.log(2);
            // if (userData ) {
            //   await firebase
            //     .firestore()
            //     .collection(PACKAGE_COLLECTION_NAME)
            //     .doc(GOLD_PACKAGE_ID)
            //     .onSnapshot((userPackage: any) => {
            //       console.log(3);
            //       const packageData = userPackage?.data();
            //       if (packageData) {
            //         if (userData["crypto"])
            //           packageData[PERMISSIONS.CRYPTO] = true;
            //       }
            //       dispatch({
            //         type: SENTIMENT_ACTION.NS_USER,
            //         payload: {
            //           user: packageData,
            //           package: userData,
            //           agreement: userData["meta.agreement"],
            //           cooperate: userData["meta.cooperate"] ?? false,
            //           loaded: true,
            //           requested: true,
            //           needRefresh: false,
            //         },
            //       });
            //     });
            // } else {
            //   dispatch({
            //     type: SENTIMENT_ACTION.NS_USER,
            //     payload: {
            //       user: null,
            //       agreement: userData ? userData["meta.agreement"] : false,
            //       cooperate: userData["meta.cooperate"] ?? false,
            //       loaded: true,
            //       requested: true,
            //       needRefresh: false,
            //     },
            //   });
            // }
          },
          (err: any) => {
            console.error("Unable to load package detail");
            console.error(err);
            dispatch({
              type: SENTIMENT_ACTION.NS_USER,
              payload: {
                user: null,
                agreement: false,
                cooperate: false,
                loaded: true,
                requested: true,
                needRefresh: true,
              },
            });
          }
        );
    } catch (err) {
      console.error("Unable to load user detail");
      console.error(err);
      dispatch({
        type: SENTIMENT_ACTION.NS_USER,
        payload: {
          user: null,
          agreement: false,
          cooperate: false,
          loaded: true,
          requested: true,
          needRefresh: true,
        },
      });
    }
  };
}

export default function NsUser(state = initialState, action: any) {
  switch (action.type) {
    case SENTIMENT_ACTION.NS_USER:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
export const nsUserSelector = (state: any) => state?.NsUser;
