import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";

export const firebaseConfig = {
  apiKey: "AIzaSyB_DadiPKlPLIt3sw_uPFr6i3KxI1WDiIs",
  authDomain: "algo-senti.firebaseapp.com",
  databaseURL: "https://algo-senti.firebaseio.com",
  projectId: "algo-senti",
  storageBucket: "algo-senti.appspot.com",
  messagingSenderId: "109562175983",
  appId: "1:109562175983:web:66ed44b4c1feae44dbd61a",
};

// const firebaseConfig = {
//   apiKey: "AIzaSyBcCaXQyScgEELvbTQLuChFRgf5xBXEubI",
//   authDomain: "crypto-sentiment-9450f.firebaseapp.com",
//   projectId: "crypto-sentiment-9450f",
//   storageBucket: "crypto-sentiment-9450f.appspot.com",
//   messagingSenderId: "142997834596",
//   appId: "1:142997834596:web:a40861634f4cc4764e0871",
//   measurementId: "G-YS5FJ0WB3Y"
// };

export const firebaseApp = firebase.initializeApp(firebaseConfig);
export const fireStore = firebaseApp.firestore();
export const FirebaseFieldValue = firebase.firestore.FieldValue;
export const auth = firebaseApp.auth();
export const storage = firebaseApp.storage()